/* eslint-disable */
import particles from './vue-particles.vue'

const VueParticles = {

    install (Vue, options) {
        Vue.component('vue-particles', particles)
    }

}

export default VueParticles
/* eslint-disable */
